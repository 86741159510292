import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useCallback, useMemo, useState, type FC, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppCtx } from '../app-ctx/mod';

import {
    DEFAULT_LANGUAGE,
    DEFAULT_REGION,
    LOCALE_COOKIE,
    SUPPORTED_REGIONS,
    getSavedCurrency,
    setLocalizationCookie,
    type Currency,
    type Language,
    type Region,
} from '@/config/localization';
import { LocalizationContext, type ILocalizationContext } from '@/core/localization/mod';

function supportedRegion(region = ''): Region {
    return SUPPORTED_REGIONS.includes(region as Region) ? (region as Region) : DEFAULT_REGION;
}

export const LocalizationProvider: FC<PropsWithChildren> = ({ children }) => {
    const { i18n } = useTranslation();
    const { appSettings } = useAppCtx();

    const [currentLanguage, currentRegion] = i18n.language.split('-');

    const [region, setRegion] = useState<Region>(supportedRegion(currentRegion));
    const [language, setLanguage] = useState<Language>((currentLanguage as Language) ?? DEFAULT_LANGUAGE);
    const [currency, setCurrency] = useState<Currency>(appSettings.localization.defaultCurrency ?? getSavedCurrency());

    const handleChangeLocale = useCallback(
        (language: Language, region: Region, save = false) => {
            const isCIMode = language === 'cimode';
            const locale = isCIMode ? language : `${language}-${region}`;

            void i18n.changeLanguage(locale);
            if (save && !isCIMode) setLocalizationCookie(LOCALE_COOKIE, locale);
        },
        [i18n],
    );

    const handleSetLanguage = useCallback(
        (language: Language, save = false) => {
            setLanguage(language);
            handleChangeLocale(language, region, save);
        },
        [handleChangeLocale, region],
    );

    const handleSetRegion = useCallback(
        (region: Region, save = false) => {
            setRegion(region);
            handleChangeLocale(language, region, save);
        },
        [handleChangeLocale, language],
    );

    const value: ILocalizationContext = useMemo(
        () => ({
            region,
            setRegion: handleSetRegion,
            setAndSaveRegion(region: Region) {
                handleSetRegion(region, true);
            },
            language,
            setLanguage: handleSetLanguage,
            setAndSaveLanguage(language: Language) {
                handleSetLanguage(language, true);
            },
            currency,
            setCurrency,
            locale: `${language}-${region}`,
        }),
        [currency, handleSetLanguage, handleSetRegion, language, region],
    );

    return (
        <LocalizationContext.Provider value={value}>
            <MuiLocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={language}>
                {children}
            </MuiLocalizationProvider>
        </LocalizationContext.Provider>
    );
};
