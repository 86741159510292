import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { DEFAULT_EXTRA_FILTER, PAX_FILTER_ELEMENTS } from '../constants';
import { type ExtraFilter, type IFilter } from '../types';

import { type BusType } from '@/assets/icons/bus-types/BusTypeIcon';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

export function getDifference<T extends Record<string, any>>(obj1: T, obj2: T): Partial<T> | null {
    const difference: Partial<T> = {};

    Object.entries(obj1).forEach(([key]) => {
        if (!isEqual(obj1[key], obj2[key])) {
            difference[key as keyof T] = obj2[key];
        }
    });

    return isEmpty(difference) ? null : difference;
}

export function getExtraFiltersFromFilter(filter: IFilter) {
    const extraFilters = { ...DEFAULT_EXTRA_FILTER };

    const isExtraFilterKey = (key: string): key is keyof ExtraFilter => key in extraFilters;

    Object.keys(filter)
        .filter(isExtraFilterKey)
        .forEach(key => {
            extraFilters[key] = filter[key] as any;
        });

    return extraFilters;
}

export const filterByRating = (result: BookingSearchResult, filter: IFilter) => {
    const { minCompanyRating } = filter;
    const rating = result.bus.company.reviewScores.totalScore;

    const ratingConditions = [];

    // Very bad
    if (minCompanyRating.includes(1)) ratingConditions.push(rating > 0 && rating < 1.5);

    // Bad
    if (minCompanyRating.includes(2)) ratingConditions.push(rating < 2.5 && rating >= 1.5);

    // Neutral
    if (minCompanyRating.includes(3)) ratingConditions.push(rating < 3.5 && rating >= 2.5);

    // Good
    if (minCompanyRating.includes(4)) ratingConditions.push(rating < 4.5 && rating >= 3.5);

    // Very good
    if (minCompanyRating.includes(5)) ratingConditions.push(rating >= 4.5);

    return ratingConditions.some(condition => condition);
};

export const busTypeByPax = (pax: number): BusType => {
    return PAX_FILTER_ELEMENTS.find(paxElement => paxElement.minValue <= pax && paxElement.maxValue >= pax)!.type;
};
