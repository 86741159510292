import { CssBaseline, GlobalStyles, ScopedCssBaseline, styled, ThemeProvider, useMediaQuery } from '@mui/material';
import InitColorSchemeScript from '@mui/material/InitColorSchemeScript';
import stylesFontAwesome from 'font-awesome/css/font-awesome.css?inline';
import React, { useEffect, useMemo, type FC, type PropsWithChildren } from 'react';
import stylesSwiperNavigation from 'swiper/css/navigation?inline';
import stylesSwiperPagination from 'swiper/css/pagination?inline';
import stylesSwiper from 'swiper/css?inline';

import { richTextCss } from '@/components/elements/rich-text-editor';
import { useAppCtx } from '@/core/app-ctx/mod';
import { useAppProgress } from '@/core/app-progress/mod';
import { AppTheme } from '@/core/theme/AppTheme';
import { useEmbedCtx } from '@/embed/mod';

export const AppThemeProvider: FC<PropsWithChildren> = ({ children }) => {
    const { themeOverrides, appSettings } = useAppCtx();
    const { isPrint } = useAppProgress();
    const { isEmbedding, breakpointScalingFactor, portalContainer } = useEmbedCtx();
    const isDesktop = useMediaQuery('(min-width: 900px)');

    useEffect(() => {
        portalContainer?.setAttribute('class', appSettings.theme.forceColorScheme ?? '');
    }, [appSettings.theme.forceColorScheme, portalContainer]);

    const theme = useMemo(
        () =>
            new AppTheme(themeOverrides, {
                breakpointScalingFactor,
                portalContainer,
                isPrint,
                isDesktop,
            }).create(appSettings.theme, isEmbedding),
        [themeOverrides, breakpointScalingFactor, portalContainer, isPrint, isDesktop, appSettings.theme, isEmbedding],
    );

    // One day this could be a Theme setting
    const defaultMode = 'light';

    return (
        <>
            <InitColorSchemeScript defaultMode={defaultMode} />

            <div className={appSettings.theme.forceColorScheme ?? ''}>
                <ThemeProvider theme={theme} defaultMode={defaultMode}>
                    {isEmbedding ? (
                        <>
                            <ExtraStyles />
                            <GlobalStyles styles={themeOverrides.customCSS} />
                            <ScopedCssBaseline>{children}</ScopedCssBaseline>
                        </>
                    ) : (
                        <>
                            <ExtraStyles />
                            <GlobalStyles styles={themeOverrides.customCSS} />
                            <CssBaseline />
                            {children}
                        </>
                    )}
                    <Fixit />
                </ThemeProvider>
            </div>
        </>
    );
};

const ExtraStyles: React.FC = () => {
    return (
        <>
            <GlobalStyles styles={stylesSwiper} />
            <GlobalStyles styles={stylesSwiperPagination} />
            <GlobalStyles styles={stylesSwiperNavigation} />
            <GlobalStyles styles={richTextCss} />
            <GlobalStyles styles={stylesFontAwesome} />
        </>
    );
};

// We need to add at least one element with display fixed to the page.
// This forces the browser to do some extra tracking which in turn
// fixes position sticky elements on mobile not behaving correctly
// when the mobile borwser's ui is retracting on scrolling.
const Fixit = styled('div')({
    display: 'fixed',
});
