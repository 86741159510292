import { DateTime } from 'luxon';

import { type Language } from '@/config/localization';
import { Entity } from '@/entity/A_Entity';
import { type Admin } from '@/entity/account/admin/Admin';
import { type SuperAdmin } from '@/entity/account/admin/SuperAdmin';
import { type Customer } from '@/entity/account/customer/Customer';
import { type Dispatcher } from '@/entity/account/dispatcher/Dispatcher';
import { type Operator } from '@/entity/account/operator/Operator';
import { ContactData } from '@/entity/basic/ContactData';
import { Document } from '@/entity/basic/Document';
import { UserRole } from '@/entity/common-constants';
import { type BookingSearchResult } from '@/entity/search-results/BookingSearchResult';

export abstract class Account extends Entity {
    public email: string;

    public readOnly: boolean;

    public avatar?: Document;

    public role: UserRole;

    public registeredAt: DateTime;

    public lastLogin: DateTime;

    public previousLogin: DateTime;

    public contactData: ContactData;

    public externalAccountId?: string;

    public language: Language;

    constructor(json: Record<string, any>) {
        super(json);
        this.email = json.email;
        this.readOnly = json.readOnly;
        this.avatar = json.avatar ? Document.fromJson(json.avatar) : undefined;
        this.registeredAt = DateTime.fromISO(json.registeredAt);
        this.lastLogin = DateTime.fromISO(json.lastLogin);
        this.previousLogin = DateTime.fromISO(json.previousLogin);
        this.contactData = ContactData.fromJson(json.contactData);
        this.externalAccountId = json.externalAccountId;
        this.language = json.language;
        this.role = json.role;
    }

    isRole(roles: UserRole[] = []): boolean {
        if (roles.length === 0) return true;
        return roles.includes(this.role);
    }

    isCustomer(): this is Customer {
        return this.role === UserRole.CUSTOMER;
    }

    isAdmin(): this is SuperAdmin | Admin {
        return this.role === UserRole.SUPER_ADMIN || this.role === UserRole.ADMIN;
    }

    isOperator(): this is Operator {
        return this.role === UserRole.OPERATOR;
    }

    isDispatcher(): this is Dispatcher {
        return this.role === UserRole.DISPATCHER;
    }

    isOperatorOrDispatcher(): this is Operator | Dispatcher {
        return this.isOperator() || this.isDispatcher();
    }

    isAdminOperatorOrDispatcher(): this is SuperAdmin | Admin | Operator | Dispatcher {
        return this.isOperator() || this.isDispatcher() || this.isAdmin();
    }

    getRoleColor(): string {
        switch (this.role) {
            case UserRole.CUSTOMER: {
                return 'green';
            }
            case UserRole.OPERATOR: {
                return 'orange';
            }
            case UserRole.DISPATCHER: {
                return 'orange';
            }
            case UserRole.ADMIN: {
                return 'red';
            }
            case UserRole.SUPER_ADMIN: {
                return 'red';
            }
            default: {
                return 'gray';
            }
        }
    }

    getRoleName(): string {
        return this.role;
    }

    /**
     * Checks if the given search result contains a bus that belongs to the user's company.
     */
    isOwnerOfSearchResult(searchResult: BookingSearchResult): boolean {
        return this.isOperatorOrDispatcher() && this.company.id === searchResult.bus.company.id;
    }

    // If the user registered before the buf-redesign launch date (07.11.2023)
    isOldUser() {
        return this.registeredAt < DateTime.fromObject({ day: 7, month: 11, year: 2023 });
    }
}

export type AppUser = Customer | Dispatcher | Operator | Admin | SuperAdmin;
