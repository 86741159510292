import { useMutation } from '@tanstack/react-query';

import callApi from '@/api';
import { type RegistrationFormValues } from '@/features/auth';

async function postRegistration(body: RegistrationFormValues) {
    const { data } = await callApi<{ id: number }>('public/registration', {
        method: 'POST',
        data: {
            ...body,
            contactData: Object.fromEntries(Object.entries(body.contactData).filter(([_, value]) => value !== '')),
        },
    });

    return data;
}

export function useRegistration() {
    return useMutation({ mutationFn: postRegistration });
}
