import { Outlet } from '@tanstack/react-router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIntegrations } from '../api/get-integrations';
import { usePatchIntegration } from '../api/patch-integration';
import {
    type Hook,
    type IIntegrationEditorContext,
    IntegrationEditorContext,
    type IntegrationOverrideables,
} from '../types';

import { IntegrationPreview } from './control/integration-preview';

import { useAuth } from '@/core/auth/mod';
import { useSnack } from '@/core/snack/mod';
import { type Integration } from '@/entity/integration/Integration';

export const IntegrationEditorProvider = () => {
    const { user } = useAuth();
    const { data: integrations, isPending } = useIntegrations(
        user?.isOperatorOrDispatcher() ? user?.company?.id : undefined,
    );
    const { t } = useTranslation('editor');
    const { mutate } = usePatchIntegration();
    const snack = useSnack();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [selectedIntegration, setSelectedIntegration] = useState<Integration | null>(null);
    const [previewData, setPreviewData] = useState<IntegrationOverrideables>({
        themeOverrides: null,
        translationOverrides: null,
        settings: null,
    });

    const hookSubs = useRef(new Set<Hook>());

    const resetPreviewData = () =>
        setPreviewData({
            themeOverrides: null,
            translationOverrides: null,
            settings: null,
        });

    const save = useCallback(
        (body: Partial<IntegrationOverrideables>, onSuccess?: (integration: Integration) => void) => {
            if (selectedIntegration)
                mutate(
                    { id: selectedIntegration.id, body },
                    {
                        onSuccess(integration) {
                            resetPreviewData();
                            snack.push(t('saved'), 'success');
                            if (onSuccess) onSuccess(integration);
                        },
                    },
                );
        },
        [mutate, selectedIntegration, snack, t],
    );

    useEffect(() => {
        if (selectedIntegration != null) return;

        setSelectedIntegration(integrations[0] ?? null);
        resetPreviewData();
    }, [integrations, selectedIntegration]);

    const value: IIntegrationEditorContext = useMemo(
        () => ({
            dirtyThemeOverrides: previewData.themeOverrides != null,
            dirtyTranslationOverrides: previewData.translationOverrides != null,
            dirtySettings: previewData.settings != null,
            integrations,
            selectedIntegration,
            isPending,
            selectIntegrationById(id: number) {
                setSelectedIntegration(integrations.find(integration => integration.id === id) ?? null);
                resetPreviewData();
            },
            save,
            preview(data) {
                setPreviewData({ ...previewData, ...data });
            },
            setPreviewOpen,
            previewOpen,
            triggerHook(type: Hook['type']) {
                hookSubs.current.forEach(hook => {
                    if (hook.type === type) hook.handler();
                });
                if (type === 'reset')
                    setPreviewData({
                        themeOverrides: null,
                        translationOverrides: null,
                        settings: null,
                    });
            },
            hook(hook) {
                hookSubs.current.add(hook);
                return () => hookSubs.current.delete(hook);
            },
        }),
        [integrations, selectedIntegration, isPending, save, previewData, previewOpen],
    );

    return (
        <IntegrationEditorContext.Provider value={value}>
            <Outlet />
            {selectedIntegration && (
                <IntegrationPreview
                    integrationKey={selectedIntegration.url}
                    previewData={previewData}
                    open={previewOpen}
                    onClose={() => setPreviewOpen(false)}
                />
            )}
        </IntegrationEditorContext.Provider>
    );
};
