import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useEffect, useMemo, useState } from 'react';

import { useLocalization } from '../localization/mod';

import { useLogin, useLogout } from '@/api/auth/mutations';
import { JOURNEY_BOOKING_QUERY_KEY } from '@/api/journey/booking/journey-booking-queries';
import { useUser } from '@/api/user/queries';
import { AuthContext, type IAuthContext } from '@/core/auth/mod';
import { Account } from '@/entity/account/Account';

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const router = useRouter();
    const { data: user, isFetching } = useUser();
    const login = useLogin();
    const logout = useLogout();
    const queryClient = useQueryClient();
    const { setAndSaveLanguage, setCurrency } = useLocalization();

    const [applyUserSettings, setApplyUserSettings] = useState(true);

    useEffect(() => {
        if (!applyUserSettings || user == null) return;
        setApplyUserSettings(false);
        setAndSaveLanguage(user.language);
        if (user.isOperatorOrDispatcher()) return setCurrency(user.company.currency);
    }, [applyUserSettings, setAndSaveLanguage, user, setCurrency]);

    const value: IAuthContext = useMemo(
        () => ({
            user,
            isFetchingUser: isFetching,
            async login(data, options = {}) {
                await login.mutateAsync(data);
                await queryClient.invalidateQueries({ queryKey: [JOURNEY_BOOKING_QUERY_KEY] });

                setApplyUserSettings(true);

                // redirectHref trumps navigate
                if (options.redirectHref) router.history.replace(options.redirectHref);
                else if (options.navigate) await router.navigate(options.navigate);
            },
            async logout(options = {}) {
                await logout.mutateAsync();
                await queryClient.invalidateQueries({ queryKey: [JOURNEY_BOOKING_QUERY_KEY] });

                if (options.navigate) await router.navigate(options.navigate);
            },
            isLoggingIn: login.isPending,
            isLoggingOut: logout.isPending,
            isNotLoggedInOrCustomer: !user || (user instanceof Account && user.isCustomer()),
        }),
        [user, isFetching, login, logout, queryClient, router],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
