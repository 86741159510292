import { type FC, type PropsWithChildren } from 'react';

import { ApplyAppLocalizationDefaults } from './ApplyAppLocalizationDefaults';
import { I18NProvider } from './I18NProvider';
import { LocalizationProvider } from './LocalizationProvider';

export const LocalizationProviderSetup: FC<PropsWithChildren> = ({ children }) => {
    return (
        <I18NProvider>
            <LocalizationProvider>
                <ApplyAppLocalizationDefaults>{children}</ApplyAppLocalizationDefaults>
            </LocalizationProvider>
        </I18NProvider>
    );
};
