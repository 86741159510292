import { boolean, object, type ObjectSchema, string } from 'yup';

import { type PreloadedCustomerDetails } from '@/entity/account/customer/PreloadedCustomerDetails';
import { Gender } from '@/entity/basic/ContactData';
import {
    customerGroupSchema,
    getDefaultContactDataValues,
    getDefaultCustomerGroupValues,
    type IContactData,
    type ICustomerGroupValues,
} from '@/utils/account';

export type RegistrationFormValues = ICustomerGroupValues & {
    email: string;
    password: string;
    passwordConfirmation: string;
    contactData: IContactData;
    referralToken: string;
    subscribedToNewsletter: boolean;
    agb: boolean;
};

export const registrationSchema: ObjectSchema<RegistrationFormValues> = customerGroupSchema.shape({
    email: string().defined().email().required(),
    password: string().defined().required().min(3).max(30),
    passwordConfirmation: string().defined().required().matchWith('password', { key: 'password.no_match' }),
    contactData: object({
        gender: string()
            .when({
                is: (value: string) => value != null && value !== '',
                then: schema => schema.oneOf(Object.values(Gender)),
            })
            .defined(),
        firstName: string().defined(),
        lastName: string().defined(),
        street: string().defined(),
        postCode: string().defined(),
        city: string().defined(),
        country: string().defined(),
        phone: string().defined(),
    }),
    referralToken: string().defined(),
    agb: boolean().defined().isTrue(),
    subscribedToNewsletter: boolean().defined(),
});

export function defaultRegistrationFormValues(): RegistrationFormValues {
    return {
        ...getDefaultCustomerGroupValues(),
        contactData: getDefaultContactDataValues(),
        email: '',
        password: '',
        passwordConfirmation: '',
        referralToken: '',
        subscribedToNewsletter: false,
        agb: false,
    };
}

export function preloadRegistrationFormValues(
    preloadedCustomerDetails?: PreloadedCustomerDetails | null,
    referralToken?: string,
): RegistrationFormValues {
    const values = defaultRegistrationFormValues();

    const {
        customerGroup,
        organizationName,
        organizationLocation,
        organizationUID,
        email,
        invitedFirstName,
        invitedLastName,
    } = preloadedCustomerDetails ?? {};

    if (customerGroup) values.customerGroupId = customerGroup.id;
    if (organizationName) values.organizationName = organizationName;
    if (organizationLocation) values.organizationLocation = organizationLocation;
    if (organizationUID) values.organizationUID = organizationUID;
    if (email) values.email = email;
    if (invitedFirstName) values.contactData.firstName = invitedFirstName;
    if (invitedLastName) values.contactData.lastName = invitedLastName;
    if (referralToken) values.referralToken = referralToken;

    return values;
}
