import CloseIcon from '@mui/icons-material/Close';
import { IconButton, DialogTitle as MuiDialogTitle } from '@mui/material';
import { type ReactNode } from 'react';

export interface DialogTitleProps {
    readonly id?: string;
    readonly disableTrivialClose?: boolean;
    readonly children: ReactNode;
    onClose?(): void;
}

export const DialogTitle = ({ id, disableTrivialClose, children, onClose }: DialogTitleProps) => {
    return (
        <MuiDialogTitle id={id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
            {children}
            {!disableTrivialClose && (
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            )}
        </MuiDialogTitle>
    );
};
