import { useSearch } from '@tanstack/react-router';
import { useEffect, useState, type FC, type PropsWithChildren } from 'react';

import { useAppCtx } from '../app-ctx/mod';

import { useLocalization } from './mod';

import { getSavedLocalizationValues } from '@/config/localization';

export const ApplyAppLocalizationDefaults: FC<PropsWithChildren> = ({ children }) => {
    // This component should not be rendered until the app settings are loaded
    const {
        appSettings: {
            localization: { defaultLanguage, defaultRegion, defaultCurrency },
        },
    } = useAppCtx();
    const { setLanguage, setRegion, setCurrency } = useLocalization();

    const [defaultsApplied, setDefaultsApplied] = useState(false);
    const search = useSearch({ strict: false });

    useEffect(() => {
        // Abort if we are done here
        if (defaultsApplied) return;

        const saved = getSavedLocalizationValues();

        // Apply defaults if not saved in cookies yet
        // Ensures user picked values are not overwritten by defaults
        if (!saved.language && defaultLanguage && search.language == null) setLanguage(defaultLanguage);
        if (!saved.region && defaultRegion) setRegion(defaultRegion);
        if (!saved.currency && defaultCurrency) setCurrency(defaultCurrency);

        setDefaultsApplied(true);
    }, [
        defaultCurrency,
        defaultLanguage,
        defaultRegion,
        defaultsApplied,
        setCurrency,
        setLanguage,
        setRegion,
        search.language,
    ]);

    return defaultsApplied ? children : null;
};
