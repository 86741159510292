import { type IFilterCounts, type ExtraFilter, type IFilter, type PaxElement } from '../types';

export const RESULT_ORDER_OPTIONS = [
    'cheapest_first',
    'cheapest_by_price_per_seat',
    'stars_best_first',
    'engine_best_first',
    'company_asc',
    'company_desc',
    'best_rating_first',
    'best_results',
] as const;

export const DEFAULT_EXTRA_FILTER: ExtraFilter = {
    equipments: [],
    busCompanies: [],
    busSeatDistance: 0,
    euroNorm: 0,
    busStars: 0,
    pax: [],
    minPax: 0,
};

export const DEFAULT_FILTER: IFilter = {
    freeCancellation: false,
    onlinePayment: false,
    ownBuses: false,
    sortBy: 'best_results',
    minCompanyRating: [],
    ...DEFAULT_EXTRA_FILTER,
};

export const CLEARED_FILTER: IFilter = {
    ...DEFAULT_FILTER,
    ...DEFAULT_EXTRA_FILTER,
    sortBy: 'cheapest_first',
};

export const DEFAULT_FILTER_COUNTS: IFilterCounts = {
    equipments: [],
    busCompanies: [],
};

export const PAX_FILTER_ELEMENTS: PaxElement[] = [
    { key: 'filter.pax.van', minValue: 1, maxValue: 8, type: 'van' },
    { key: 'filter.pax.minibus', minValue: 9, maxValue: 22, type: 'minibus' },
    { key: 'filter.pax.midibus', minValue: 23, maxValue: 39, type: 'midibus' },
    { key: 'filter.pax.tour_bus', minValue: 40, maxValue: 59, type: 'reisebus' },
    { key: 'filter.pax.xl-bus', minValue: 60, maxValue: Number.POSITIVE_INFINITY, type: 'xl-bus' },
];

export const MIN_PAX_FILTER_THRESHOLD = 50;
