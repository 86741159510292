import { createContext } from 'react';

/**
 * Config of available dialog variants and their associated actions.
 * The order of the actions is the order they will appear in the dialog.
 */
export const DialogVariantActions = {
    confirm: ['cancel', 'ok'],
    info: ['ok'],
    form: ['cancel', 'reset', 'submit'],
} as const;

// t('ui.dialog.confirm.actions.cancel')
// t('ui.dialog.confirm.actions.ok')
// t('ui.dialog.info.actions.ok')
// t('ui.dialog.form.actions.cancel')
// t('ui.dialog.form.actions.reset')
// t('ui.dialog.form.actions.submit')

/**
 * Extracts union type of the variants from the config.
 */
export type DialogVariant = keyof typeof DialogVariantActions;

/**
 * Extracts union type of the actions associated to a variant from the config.
 */
export type DialogVariantAction<Variant extends DialogVariant = DialogVariant> =
    (typeof DialogVariantActions)[Variant][number];

export interface IDialogContext {
    id: string;
    wrappingDialogVariant: DialogVariant | false;
    close(): void;
}

export const DialogContext = createContext<IDialogContext>({
    id: '',
    wrappingDialogVariant: false,
    close() {},
});
