import { Divider, Stack } from '@mui/material';
import { type FC } from 'react';

import { TopEquipmentsIcons } from '../elements/top-equipment-icons';

import BusSeatsInfo from '@/components/bus/bus-seats-info';
import { useLayout } from '@/components/page/layout/mod';
import { useAppCtx } from '@/core/app-ctx/mod';
import { type BookingBus } from '@/entity/bus/BookingBus';

interface BusCardInfoProps {
    readonly bus: BookingBus;
}

export const BusCardInfo: FC<BusCardInfoProps> = ({ bus }) => {
    const { isSmallMobile } = useLayout();
    const { integration } = useAppCtx();
    const topEquipments = bus.getTopEquipments(integration?.emileWeber);

    return (
        <Stack direction={isSmallMobile ? 'row' : 'column'} justifyContent="space-between">
            <BusSeatsInfo bus={bus} variant="bus-info" />

            <Stack
                direction="row"
                divider={isSmallMobile ? null : <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />}>
                <BusSeatsInfo hideInfoIconMobile={isSmallMobile} bus={bus} variant="seats" />
                {!isSmallMobile && <TopEquipmentsIcons topEquipments={topEquipments} />}
            </Stack>
        </Stack>
    );
};
