import SchoolIcon from '@mui/icons-material/School';
import { Autocomplete, Box, createFilterOptions, Grid2, TextField, Typography } from '@mui/material';
import { useDebounce } from '@react-hook/debounce';
import omit from 'lodash/omit';
import uniqueId from 'lodash/uniqueId';
import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';

import { useSchoolsByKeyword } from '@/api/public';
import { ErrorMessage } from '@/components/form/error-message';
import { type FormFieldProps } from '@/components/form/mod';
import { School } from '@/entity/school/School';

/**
 *  Change the default filter behavior to limit the suggested options to 50 with {@link createFilterOptions}
 */
const filterOptions = createFilterOptions<School>({
    stringify: school => school.schoolName + school.contactData.fullAddress,
    limit: 50,
    ignoreCase: true,
    trim: true,
});

interface SchoolAutocompleteProps extends FormFieldProps {
    readonly onChangeAddress?: (address: string) => void;
    readonly onBlurField?: (schoolName: string) => void;
}

export const SchoolAutocomplete: React.FC<SchoolAutocompleteProps> = ({
    name,
    label,
    helperText,
    onChangeAddress,
    onBlurField,
}) => {
    const { field, fieldState } = useController({ name });
    const [debouncedValue, setDebouncedValue] = useDebounce<string>(field.value ?? '', 850);
    const { data: schools } = useSchoolsByKeyword(debouncedValue);

    useEffect(() => {
        console.log('field.value', name, field.value);
    }, [field.value, name]);

    return (
        <Autocomplete
            freeSolo
            filterSelectedOptions
            value={field.value}
            options={schools ?? []}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, v) => option.id === v.id}
            getOptionLabel={school => getSchoolName(school)}
            renderInput={params => (
                <TextField
                    required
                    name={name}
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error ? <ErrorMessage error={fieldState.error} /> : helperText}
                    {...params}
                    label={label}
                />
            )}
            renderOption={renderAutocompleteResults}
            onChange={(_e, item) => {
                field.onChange(getSchoolName(item));
                if (onChangeAddress && item instanceof School)
                    onChangeAddress(`${item.contactData.postCode} ${item.contactData.street}`);
            }}
            onInputChange={(_e, newInputValue) => {
                field.onChange(newInputValue);
                setDebouncedValue(newInputValue);
            }}
            onBlur={() => {
                onBlurField?.(field.value);
                field.onBlur();
            }}
        />
    );
};

const getSchoolName = (school: School | string | null) => {
    return typeof school === 'string' ? school : school?.schoolName || '';
};

function renderAutocompleteResults(props: React.HTMLAttributes<HTMLLIElement>, school: School) {
    return (
        <li key={uniqueId('school_prediction')} {...omit(props, 'key')}>
            <Grid2 container alignItems="center">
                <Grid2>
                    <Box component={SchoolIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                </Grid2>
                <Grid2 size="grow">
                    <span>{school?.schoolName}</span>
                    <Typography variant="body2" color="text.secondary">
                        {school?.contactData.fullAddress}
                    </Typography>
                </Grid2>
            </Grid2>
        </li>
    );
}
