import { DateTime } from 'luxon';

import { type CustomerGroupType } from '@/entity/common-constants';
import { Review } from '@/entity/reviews/Review';

export class ReviewBooking extends Review {
    public customerShortName: string;

    public verified: boolean;

    public customerGroupType: CustomerGroupType;

    public entries: Record<string, number>;

    public operatorAnswerState?: string;

    public operatorAnswer?: string;

    public modifiedAt: DateTime;
    constructor(json: Record<string, any>) {
        super(json);

        this.customerShortName = json.customerShortName;

        this.verified = json.verified;

        this.customerGroupType = json.customerGroupType;

        this.entries = json.entries;

        this.operatorAnswerState = json.answerStatus;

        this.operatorAnswer = json.operatorAnswer;

        this.modifiedAt = DateTime.fromISO(json.modifiedAt);
    }
}
