import React from 'react';

import { StopAlert } from './stop-alert';

import { useAppCtx } from '@/core/app-ctx/mod';
import { type IPosition, useJourneyPlanningForm } from '@/features/journey-planning';

interface StopAlertsProps {
    readonly isAnyFieldBeingDragged: boolean;
    readonly position: IPosition;
}

export const StopAlerts: React.FC<StopAlertsProps> = ({ position, isAnyFieldBeingDragged }) => {
    const { getBookingStop } = useJourneyPlanningForm();
    const bookingStop = getBookingStop(position);
    const { integration } = useAppCtx();

    if (!bookingStop?.legDetails || isAnyFieldBeingDragged) return null;

    const alerts = [...bookingStop.getAlerts(), ...bookingStop.legDetails.getAlerts(integration?.emileWeber)];

    return alerts.map(status => <StopAlert {...status} key={status.t_title} />);
};
