import { Marker } from '@react-google-maps/api';
import { useCallback, useEffect, useRef } from 'react';

import { useTripCompanies } from '../hooks/use-trip-companies';
import { type PopupState } from '../types';
import { tripIcon } from '../utils';

import { type TripCompany } from '@/entity/trip/company/TripCompany';

interface TripCompanyMarkerProps {
    readonly company: TripCompany;
    readonly onMouseOver: (state: PopupState) => void;
    readonly onMouseOut: () => void;
}

// TODO: Replace this with trip markers to make them Themeable. Requires AdvancedMapMarkers or usage of an OverlayView similar
// to the normal journey map.
export const TripCompanyMarker = ({ company, onMouseOver, onMouseOut }: TripCompanyMarkerProps) => {
    const { hoveredCompany } = useTripCompanies();
    const markerRef = useRef<Marker>(null);

    const { categories, id, companyName, location } = company;

    const setIcon = useCallback(
        (hovered: boolean, scaledSize: google.maps.Size | null = null) => {
            markerRef.current?.marker?.setIcon({
                url: tripIcon({ categories, hovered }),
                scaledSize,
            });
            markerRef.current?.marker?.setZIndex(hovered ? google.maps.Marker.MAX_ZINDEX : null);
        },
        [categories],
    );

    const handleMouseOver = () => {
        setIcon(true);
        onMouseOver({ position: markerRef.current?.marker?.getPosition() ?? undefined, company });
    };

    const handleMouseOut = () => {
        setIcon(false);
        onMouseOut();
    };

    useEffect(() => {
        if (hoveredCompany === id) {
            markerRef.current?.marker?.setAnimation(google.maps.Animation.BOUNCE);
            // TODO: Instead of scaling the icon, we should use a different icon with a different size. Right now, the icon is scaled, and it looks pixelated.
            setIcon(true, new google.maps.Size(27.5, 41));
        } else {
            markerRef.current?.marker?.setAnimation(null);
            setIcon(false);
        }
    }, [hoveredCompany, id, setIcon]);

    return (
        <Marker
            ref={markerRef}
            key={id}
            visible
            title={companyName}
            position={location.toGMapsLatLng()}
            icon={tripIcon({ categories, hovered: false })}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        />
    );
};
