import { Outlet, createFileRoute } from '@tanstack/react-router';
import { object, string, type InferType } from 'yup';

import { AUTHENTICATION_TOKEN_STORAGE_KEY } from '@/api';
import { setLocalStorageItem } from '@/utils/storage';

// Parent
const R_PRINT_SEARCH_PARAMS = object({
    integrationName: string(),
    jSessionId: string(),
    token: string(),
});

export const Route = createFileRoute('/print')({
    component: Outlet,

    validateSearch(search): InferType<typeof R_PRINT_SEARCH_PARAMS> {
        return R_PRINT_SEARCH_PARAMS.cast(search);
    },
    beforeLoad({ search }) {
        // If the jSessionId is present, store it in the local storage, so that it can be used for fetching an account on the print page. Otherwise, the account related discounts are not printed.
        if (search.jSessionId) {
            setLocalStorageItem(AUTHENTICATION_TOKEN_STORAGE_KEY, search.jSessionId);
        }

        return {
            print: true,
        };
    },
});

export { Route as PrintRoute };
