import { SvgIcon, type SvgIconProps } from '@mui/material';
import { forwardRef, type FC } from 'react';

export const NoToilet: FC<SvgIconProps> = forwardRef((props, ref) => (
    <SvgIcon ref={ref} {...props} viewBox="0 0 24 24">
        <path d="M2.4815 17.2752.1605 6.7248A.2674.2674 128.7963 0 1 .4215 6.4h1.527a.4052.4052 39.3786 0 1 .3973.3262L3.849 14.288 6.0165 8.71a.486.486 145.6177 0 1 .453-.31h1.351a.4865.4865 34.356 0 1 .4532.3099l2.1673 5.5621 1.5184-7.546a.4061.4061 140.6885 0 1 .3982-.326h1.4468a.2674.2674 51.2037 0 1 .2611.3248l-2.321 10.5504a.4137.4137 141.2037 0 1-.404.3248h-1.575a.4799.4799 34.722 0 1-.4493-.3114L7.129 11.456l-2.2178 5.8331a.4822.4822 145.4086 0 1-.4508.3109H2.8856a.4137.4137 38.7963 0 1-.404-.3248Zm17.0135.5008c-.8213 0-1.584-.144-2.288-.432-.704-.2987-1.3227-.7093-1.856-1.232-.5227-.5227-.9333-1.136-1.232-1.84-.2987-.704-.448-1.4613-.448-2.272 0-.8213.1493-1.5787.448-2.272.2987-.704.7093-1.3173 1.232-1.84.5333-.5227 1.152-.928 1.856-1.216.704-.2987 1.4667-.448 2.288-.448.5867 0 1.152.0853 1.696.256.544.16 1.0507.3893 1.52.688.3727.2372.7017.5147.987.8327.1226.1366.106.3588-.0261.4864l-.9618.9298c-.132.1276-.3286.1118-.4506-.0254-.3343-.376-.7118-.6692-1.1325-.8795-.5013-.256-1.0453-.384-1.632-.384-.512 0-.992.1013-1.44.304-.4373.192-.8213.464-1.152.816-.32.352-.5707.7627-.752 1.232-.1813.4587-.272.9653-.272 1.52s.0907 1.0667.272 1.536c.1813.4587.432.864.752 1.216.3307.352.7147.6293 1.152.832.448.192.928.288 1.44.288.5867 0 1.1307-.128 1.632-.384.4197-.2186.7964-.5124 1.13-.8815.1232-.1362.321-.151.4531-.0234l.9618.9298c.132.1276.1472.3485.0234.4842-.2846.3117-.6127.59-.9843.8349-.4693.2987-.976.5333-1.52.704-.544.16-1.1093.24-1.696.24z" />
        <path
            d="M3.6963 2.4128a1.2837 1.2837 0 0 0-.9093.3744 1.2837 1.2837 0 0 0 0 1.8168l16.6092 16.6092a1.2837 1.2837 0 0 0 1.8168 0 1.2837 1.2837 0 0 0 0-1.8168L4.6038 2.7872a1.2837 1.2837 0 0 0-.9075-.3744Z"
            stroke="#fff"
            strokeWidth=".85"
            strokeLinecap="round"
        />
    </SvgIcon>
));
