import { EQUIPMENT_ICONS } from '@/components/bus/mod';
import { Entity } from '@/entity/A_Entity';

export class Equipment extends Entity {
    public name: string;

    public rating?: number;

    public bestOption?: boolean;

    public icon?: JSX.Element;

    public color?: 'eco' | 'warning';

    public disableFilter: boolean;

    constructor(json: Record<string, any>) {
        super(json);
        this.name = json.name;
        this.rating = json.rating;
        this.bestOption = json.bestOption;

        const additionalData = EQUIPMENT_ICONS.find(eq => eq.id === this.id);

        this.icon = additionalData?.icon;
        this.color = additionalData?.color;
        this.disableFilter = additionalData?.disableFilter ?? false;
    }
}
