import React from 'react';
import { useTranslation } from 'react-i18next';

import { CountrySelect } from '@/components/form/fields/country-select';
import { SelectField } from '@/components/form/fields/select-field';
import { TextField } from '@/components/form/fields/text-field';
import { FormFieldRow } from '@/components/form/layout/form-field-row';
import { FormFieldsContainer } from '@/components/form/layout/form-fields-container';
import { genderOptions } from '@/utils/constants';

interface ContactDataInputsProps {
    readonly withoutEmail?: boolean;
    readonly optional?: boolean;
}

export const ContactDataInputs: React.FC<ContactDataInputsProps> = ({ withoutEmail, optional = false }) => {
    const { t } = useTranslation();

    return (
        <FormFieldsContainer containerLabel={t('contact_data')} collapsible={optional}>
            <FormFieldRow>
                <SelectField
                    required={!optional}
                    name="contactData.gender"
                    label={t('account.core_data.gender.gender')}
                    options={genderOptions()}
                    data-cy="gender"
                />

                <TextField required={!optional} name="contactData.phone" label={t('account.core_data.phone_number')} />
            </FormFieldRow>

            <FormFieldRow>
                <TextField
                    required={!optional}
                    name="contactData.firstName"
                    label={t('account.core_data.first_name')}
                />
                <TextField required={!optional} name="contactData.lastName" label={t('account.core_data.last_name')} />
            </FormFieldRow>

            <FormFieldRow>
                <CountrySelect required={!optional} name="contactData.country" label={t('account.core_data.country')} />

                <TextField required={!optional} name="contactData.street" label={t('account.core_data.street')} />
            </FormFieldRow>

            <FormFieldRow>
                <TextField required={!optional} name="contactData.city" label={t('account.core_data.city')} />

                <TextField required={!optional} name="contactData.postCode" label={t('account.core_data.post_code')} />
            </FormFieldRow>

            <FormFieldRow insertEmptyColumn>
                <TextField
                    required={!optional}
                    type={withoutEmail ? 'hidden' : 'text'}
                    name="contactData.email"
                    label={t('account.core_data.email')}
                />
            </FormFieldRow>
        </FormFieldsContainer>
    );
};
